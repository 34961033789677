<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showexp"
      max-width="800px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span>
            Montant :
            {{
              numberWithSpace(
                fact ? (fact.mont_ttc ? fact.mont_ttc.toFixed(2) : "0") : "0"
              ) + " DA"
            }}
          </span>
          <v-spacer></v-spacer>
          <span>
            Expeditions :
            {{
              numberWithSpace(
                fact ? (fact.mont_exp ? fact.mont_exp.toFixed(2) : "0") : "0"
              ) + " DA"
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <listitems
            :list="exp_list"
            :headers="expheaders"
            :master="false"
            :add="add"
            :del="del"
            :Update="false"
            :showedit="false"
            @open="add_exp"
            @delete="del_exp"
          >
          </listitems>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" text @click.stop="closeExp">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import listitems from "./ListItems.vue";
export default {
  components: {
    listitems,
  },
  props: {
    fact: Object,
    exp_list: Array,
    showexp: Boolean,
    facture: { type: Boolean, default: true },
    add: { type: Boolean, default: false },
    del: { type: Boolean, default: false },
  },
  data() {
    return {
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",

      expheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Expedition No",
          value: "nobon",
          selected: true,
        },
        {
          text: "Reference",
          value: "exp_ref",
          selected: true,
        },
        {
          text: "Date ",
          value: "opr_date",
          slot: "date",
          selected: true,
        },

        {
          text: "TTC",
          value: "mont_ttc",
          selected: true,
          slot: "cur",
          align: "end",
        },
        {
          text: "Montant Facture",
          value: "mont_fact",
          selected: true,
          slot: "cur",
          align: "end",
        },
      ],
    };
  },
  methods: {
    add_exp() {
      this.$emit("add");
    },
    del_exp(item) {
      this.$emit("del", item);
    },
    closeExp() {
      this.$emit("close");
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
